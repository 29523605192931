import {injectArguments} from '@/container/injections';
import {Feature} from '@/services/api/interfaces/me';
import {IConfigService} from '@/services/config/IConfigService';
import {IFeatureService} from '@/services/feature/IFeatureService';

import {insertScript} from './insert-script';

const isDev = process.env.NODE_ENV === 'development';

export const insertJivoScript = injectArguments(
	[IFeatureService, IConfigService],
	(featureService: IFeatureService, configService: IConfigService) => () => {
		if (isDev || !featureService.checkIfFeatureExist(Feature.JivoSiteLandings)) {
			return;
		}

		insertScript({
			documentTag: 'head',
			src: `//code.jivosite.com/widget/${configService.config.JIVOSITE_ID}`,
			async: true,
		});
	},
);
