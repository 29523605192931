import React from 'react';

import {Section} from '@/components/common/section/Section';
import {Logo as LogoVKWorkSpace} from '@/components/page/404/logo/Logo';

export function Header() {
	return (
		<header>
			<Section>
				<LogoVKWorkSpace />
			</Section>
		</header>
	);
}
