import classNames from 'classnames';
import startCase from 'lodash-es/startCase';
import throttle from 'lodash-es/throttle';
import {forwardRef, PropsWithChildren, ReactNode, UIEventHandler, useCallback, useState} from 'react';

import styles from './TemplateForFixedHeader.module.css';

interface TemplateForFixedHeaderProps {
	headChildren: ReactNode;
	type?: 'default' | 'contrast';
	contentStyle?: string;
	className?: string;
}

const maxHeight = 36;

const TemplateForFixedHeader = forwardRef<HTMLDivElement, PropsWithChildren<TemplateForFixedHeaderProps>>(
	({headChildren, type = 'default', contentStyle, children, className}, ref) => {
		const [contentScrollTop, setContentScrollTop] = useState<number>(0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
			throttle((event) => {
				const element: HTMLDivElement = event.target as HTMLDivElement;
				setContentScrollTop(element.scrollTop);
			}, 100),
			[setContentScrollTop],
		);

		return (
			<div className={classNames(styles.root, className)} onScroll={handleScroll} ref={ref}>
				<div
					className={classNames(styles.headerBorder, {
						[styles[`headerShadowType${startCase(type)}`]]: contentScrollTop > maxHeight,
					})}
				>
					{headChildren}
				</div>
				<div className={contentStyle}>{children}</div>
			</div>
		);
	},
);

TemplateForFixedHeader.displayName = 'TemplateForFixedHeader';

export {TemplateForFixedHeader};
