import classNames from 'classnames';

import {PAGES} from '@/constants';
import VKWorkSpaceLogo from '@/icons/logos/vk-work-space-logo-light.svg';
import {Media, MediaContextProvider} from '@/media';

import styles from './Logo.module.css';

export function Logo() {
	const className = classNames(styles.wrapper, styles.clickable);

	return (
		<a href={PAGES.workSpace.href}>
			<MediaContextProvider>
				<Media at="mobile" className={className}>
					<VKWorkSpaceLogo width={139} height={23} />
				</Media>
				<Media greaterThanOrEqual="desktop" className={className}>
					<VKWorkSpaceLogo width={199} height={32} />
				</Media>
			</MediaContextProvider>
		</a>
	);
}
