import {when} from 'mobx';
import {useEffect} from 'react';

import {MaybePromise} from '@/utilites/utility-types';

export function useOnceWhenEffect(predicate: () => boolean, effect: () => MaybePromise<void>): void {
	useEffect(() => {
		when(predicate, effect);
	}, []);
}
