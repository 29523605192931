import type {NextPage} from 'next';
import Head from 'next/head';
import {useEffect} from 'react';

import Footer from '@/components/common/footer/Footer';
import {PortalHeader} from '@/components/common/portal-header/PortalHeader';
import {TemplateForFixedHeader} from '@/components/common/template-for-fixed-header/TemplateForFixedHeader';
import {Header} from '@/components/page/404/header/Header';
import {MainSection} from '@/components/page/404/main-section/MainSection';
import appleTouchIcon from '@/components/page/vk-work-space/assets/apple-touch-icon.png';
import favicon from '@/components/page/vk-work-space/assets/favicon.ico';
import favicon16 from '@/components/page/vk-work-space/assets/favicon-16x16.png';
import favicon32 from '@/components/page/vk-work-space/assets/favicon-32x32.png';
import {useInjection} from '@/hooks/use-injection';
import {useOnceWhenEffect} from '@/hooks/use-once-when-effect';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {getCanonicalUrl} from '@/utilites/get-canonical-url';
import {insertJivoScript} from '@/utilites/script/insert-jivo-script';

import styles from './404.module.css';
import variablesStyles from './404-variables.module.css';
import ogImage from './assets/404-og.png';

const title = 'VK WorkSpace - 404';
const description = '«VK WorkSpace» - страница не найдена (404)';

const headChildren = <Header />;

interface Page404 {}

const Page404: NextPage<Page404> = () => {
	const canonicalUrl = getCanonicalUrl(404);

	const [featureService] = useInjection(IFeatureService);

	useEffect(() => {
		featureService.start();

		return () => {
			featureService.stop();
		};
	});

	useOnceWhenEffect(() => featureService.isServiceStarted, insertJivoScript);

	return (
		<>
			<PortalHeader isLight />
			<div className={styles.root}>
				<Head>
					<title>{title}</title>
					<meta name="description" content={description} />

					<meta property="og:url" content={canonicalUrl} />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={description} />
					<meta property="og:image" content={ogImage.src} />

					<link rel="canonical" href={canonicalUrl} />
					<link rel="icon" href={favicon.src} />
					<link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon.src} />
					<link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
					<link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
					<meta name="msapplication-TileColor" content="#2d89ef" />
					<meta name="theme-color" content="#111111" />
				</Head>
				<TemplateForFixedHeader
					headChildren={headChildren}
					className={variablesStyles.cssLocalVariables}
				>
					<MainSection />
				</TemplateForFixedHeader>
				<Footer />
			</div>
		</>
	);
};

export default Page404;
