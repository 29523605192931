import {Button} from '@/components/base/button/Button';
import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import {Section} from '@/components/common/section/Section';
import {Animation} from '@/components/page/404/animation/Animation';
import {PAGES} from '@/constants';

import styles from './MainSection.module.css';

export function MainSection() {
	return (
		<Section className={styles.sectionMain}>
			<div className={styles.textBlock}>
				<Typography variant={VARIANTS.h1} className={styles.title}>
					Страница не&nbsp;найдена
				</Typography>
				<Typography variant={VARIANTS.accentText} className={styles.text}>
					Запрашиваемая вами страница не&nbsp;найдена или не&nbsp;существует
				</Typography>
				<div className={styles.buttonContainer}>
					<Button as="a" href={PAGES.workSpace.href}>
						Перейти на&nbsp;главную
					</Button>
				</div>
			</div>
			<Animation />
		</Section>
	);
}
